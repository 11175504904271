import axios from 'axios';
import { trackLead } from './analytics/shinesty-analytics';
import { validateEmail } from '/utils/common-utils';

const validKeys = [
	'footer',
	'free-for-a-year',
	'sub-unavailable',
	'sub-unavailable-checked',
	'landing-page',
];

export const subscribeEmail = async ({
	email,
	listKeys,
	executeRecaptcha,
	recaptchaKey,
	successMessage,
}) => {
	// the email is invalid
	if (!validateEmail(email)) {
		return {
			message: 'Please check that the email you entered is not missing characters and try again.',
			status: 'error',
		};
	}

	// the payload passed to the function is bad
	if (!listKeys || listKeys.length === 0) {
		return {
			message: 'Payload incorrect - missing keys.',
			status: 'error',
		};
	}

	const formattedKeys = listKeys.reduce((memo, key) => {
		if (validKeys.includes(key)) {
			memo.push(key);
		}

		return memo;
	}, []);

	if (formattedKeys.length === 0) {
		return {
			message: "Keys were passed, but they ain't valid.",
			status: 'error',
		};
	}

	// recaptcha token gets fetched client side and validated serverside
	const recaptchaResponse = await executeRecaptcha(recaptchaKey);

	if (!recaptchaResponse) {
		return {
			message: 'Oops. Invalid captcha. Please try again.',
			status: 'error',
		};
	}

	const defaultError = {
		message: 'Not sure what happened, please try again.',
		status: 'error',
	};

	// finally call the api
	return axios({
		method: 'put',
		url: `${process.env.NEXT_PUBLIC_SHINE_API}/public/email-list`,
		data: {
			email,
			lists: formattedKeys,
			recaptchaResponse,
		},
		responseType: 'json',
	})
		.then((response) => {
			if (!response?.data || !response.data[0] || !response.data[0].success) {
				return defaultError;
			}

			trackLead(email);

			return {
				message: successMessage,
				status: 'success',
			};
		})
		.catch((e) => {
			return { axiosException: e, ...defaultError };
		});
};
